
    import Vue from 'vue'
    import api from '@api/article/apiMethod'

    const PortalHeader = () => import('../../component/common/PortalHeader.vue')
    const NewsBlock = () => import('../../component/common/NewsBlock.vue')
    const NCommonTitle = () => import('@corecp/NCommonTitle.vue')
    const PortalFooter = () => import('../../component/common/PortalFooter.vue')

    export default Vue.extend({
        name: 'ArticleList',
        components: {
            PortalHeader,
            NewsBlock,
            NCommonTitle,
            PortalFooter
        },
        data () {
            return {
                articleDetail: {
                },
                channelId: undefined,
                channelList: [],
                articleId: undefined
            }
        },
        methods: {
            async queryArticle () {
                const self = this as any
                await api.articleQuery({
                    data: {
                        filterConds: self.buildFilterConds({
                            moduleId: self.channelId,
                            articleId: self.articleId
                        })
                    },
                    success (res: any) {
                        if (res.data.length > 0) {
                            self.articleDetail = res.data[0]
                        }
                    }
                })
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            async addView () {
                const self = this
                await api.addView({
                    data: {
                        articleId: self.articleId
                    }
                })
            }
        },
        async created () {
            const self = this as any
            self.channelId = self.$route.query.channel_id
            self.channelList = self.$route.query.channel_name ? self.$route.query.channel_name.split('_') : []
            self.articleId = self.$route.query.article_id
            await self.addView()
            await self.queryArticle()
        }
    })
